
import cloudbase from "@cloudbase/js-sdk";


const cloudbaseApp = cloudbase.init({
  env: "sign-3gn0hdlk21b6ef1c"
})

const Auth = cloudbaseApp.auth({
  persistence: "local"
})

export const auth = Auth

// 获取 cloud 登录状态
export const loginStatus = () => {
  return Auth.hasLoginState();
}

// 获取当前用户信息
export const getUser = () => {
  return Auth.getCurrenUser();
}

// 更新用户信息
export const updateUserInfo = (data) => {
  const user = Auth.currentUser;
  return user
    .update(data)
}

// 退出
export const signOut = () => {
  return Auth.signOut();
}

// 发送验证码
export const sendPhoneCode = (phone) => Auth.sendPhoneCode(phone)

// 手机号注册
export const signUpWithPhoneCode = (phone, code, defaultPwd) => Auth.signUpWithPhoneCode(phone, code, defaultPwd)

// 手机号+短信登录
export const signInWithPhoneCode = (phone, code) => Auth.signInWithPhoneCodeOrPassword({
  phoneNumber: phone,
  phoneCode: code
})

// 手机号+密码登录
export const signInWithPhoneNumberAndPassword = (phone, pwd) => Auth.signInWithPhoneCodeOrPassword({
  phoneNumber: phone,
  password: pwd
})

<template>
  <el-affix class="header-affix" @change="onFixedChange">
    <div class="header-wrap" :class="{ 'header-fixed': fixed }">
      <div class="header">
        <router-link to="/" class="logo">
          <img :src="require('@/assets/img/logo.png')" />
        </router-link>

        <div class="right-wrap">
          <div class="nav-list">
            <router-link :class="{ active: activeNav === 0 }" to="/">首页</router-link>
            <router-link :class="{ active: activeNav === 4 }" to="/products">产品中心</router-link>
            <router-link :class="{ active: activeNav === 2 }" to="/service">专业护航</router-link>
            <router-link :class="{ active: activeNav === 1 }" to="/chatGPT">智能聊天</router-link>
            <router-link :class="{ active: activeNav === 3 }" to="/aboutus">关于我们</router-link>
          </div>
          <el-dropdown class="dropdown mr-10" v-if="user">
            <span class="trigger login-btn">
              <el-button class="mr-5" size="small" type="primary" icon="UserFilled" circle></el-button> {{ signInUser.str }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="goToProfile">用户信息</el-dropdown-item>
                <el-dropdown-item @click="signout">安全退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <div class="login-btn mr-20" @click="showLoginDialog" v-else>
            <el-button class="mr-5" size="small" type="primary" icon="UserFilled" circle></el-button>
            <span class="login-btn-text">用户登录</span>
          </div>
          <div class="contact">
            <el-button class="mr-5" size="small" type="primary" icon="Phone" circle></el-button>
            <span class="contact-text">0574-87428826</span>
          </div>
        </div>
      </div>
    </div>
  </el-affix>

  <div class="right-fix-server">
    <div class="online-service-wrap">
      <el-button type="primary" size="large" icon="Service" circle />
      <span>在线客服</span>
      <div class="kefu-popup">
        <img :src="require('@/assets/img/kefu.jpg')" />
      </div>
    </div>
  </div>

  <el-dialog v-model="show" class="login-dialog" size="small" title="用户登录" width="400px">
    <login @on-login="onLogin" />
  </el-dialog>
</template>

<script>
import * as auth from '@/auth.js'
const { signOut, getUser } = auth
import { ElMessage, ElMessageBox } from 'element-plus'
import * as util from '@/util'
import Login from '@/components/Login.vue'

export default {
  data() {
    return {
      activeIndex: 'home',
      user: null,
      signInUser: {},
      show: false,
      activeNav: -1,
      fixed: false
    }
  },
  components: {
    Login
  },
  watch: {
    '$route.path'(n) {
      console.log(n)
    }
  },
  methods: {
    setNavActive(index) {
      this.activeNav = index
    },
    goToHome() {
      this.$router.push({
        name: 'Home'
      })
    },
    goToProfile() {
      this.$router.push({
        name: 'AdminProfile'
      })
    },
    // 退出
    async signout() {
      ElMessageBox.confirm('是否确认退出?', '退出提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          try {
            await signOut()
            this.$store.commit('SET_USER_AUTH', null)
            this.$router.replace({
              name: 'Home'
            })
            this.user = null
          } catch (error) {
            ElMessage.error(util.getError(error))
          }
        })
        .catch(() => {})
    },
    showLoginDialog() {
      this.show = true
    },
    // 登录后获取用户数据
    async onLogin() {
      this.show = false
      await this.getUser()
    },
    async getUser() {
      const user = await getUser()
      if (user) {
        this.user = user
        this.signInUser = util.getUserPhone(user.phone)
      }
    },
    // 刷新
    async refreshUser() {
      await this.getUser()
    },
    onFixedChange(val) {
      this.fixed = val
    }
  },
  async created() {
    await this.getUser()
  }
}
</script>

<style lang="less">
.right-fix-server {
  z-index: 999;
  position: fixed;
  right: 10px;
  left: auto;
  top: 300px;
}
.kefu-popup {
  visibility: hidden;
  position: absolute;
  width: 160px;
  height: 160px;
  left: -180px;
  padding: 5px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #fff;
  z-index: 999;
  &:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    right: -20px;
    top: 50%;
    margin-top: -6px;
    border-left: 12px #fff solid;
    border-right: 12px transparent solid;
    border-top: 12px transparent solid;
    border-bottom: 12px transparent solid;
  }
  img {
    width: 100%;
  }
}
.online-service-wrap {
  position: relative;
  cursor: pointer;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  width: 90px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
  .el-button--large {
    font-size: 30px;
  }
  &:hover .kefu-popup {
    visibility: visible;
  }
  span {
    margin-top: 5px;
    color: #000;
    font-size: 14px;
  }
}
.header-wrap {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 20px;
  z-index: 99;
  transition: all 0.2s;
}
.header-fixed {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.header {
  width: 1200px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .trigger {
    outline: 0;
    font-size: 14px;
    &:hover {
      border-color: transparent;
    }
  }
  .logo {
    text-decoration: none;
    color: #777;
    img {
      height: 45px;
    }
  }
}
.right-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.nav-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 80px;
  a {
    color: #000;
    font-size: 16px;
    text-decoration: none;
    margin: 0 15px;
    font-weight: bold;
    position: relative;
  }
  a.active,
  a.router-link-active {
    color: #fe2c55;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: #fe2c55;
      transform: translate3d(-50%, 0, 0);
      bottom: -6px;
    }
  }
}

.login-btn {
  cursor: pointer;
}
.contact,
.login-btn {
  display: flex;
  align-items: center;
  .login-btn-text {
    font-size: 14px;
    font-weight: bold;
  }
  .contact-text {
    font-size: 16px;
    font-weight: bold;
  }
}
.login-dialog {
  .el-dialog__header {
    padding: 10px 20px;
  }
  .el-dialog__title {
    font-weight: bold;
    font-size: 14px;
  }
  .el-dialog__headerbtn {
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
  }
  .el-dialog__body {
    padding: 10px 15px 15px;
  }
}

.header-affix {
  position: relative;
  z-index: 99;
}
</style>

<template>
  <el-form ref="loginForm" :model="form" v-loading="loginLoading" :rules="rules">
    <el-form-item prop="phone">
      <el-input v-model="form.phone" placeholder="手机号" />
    </el-form-item>
    <el-form-item prop="code">
      <el-input v-model="form.code" placeholder="验证码">
        <template #append>
          <el-button :disabled="disabled" type="primary" @click="disabled ? null : getCode()">{{ btnText }}</el-button>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item style="margin-bottom: 0">
      <el-button style="width: 100%" @click="loginLoading ? null : login()" :loading="loginLoading" type="primary">登 录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { toRefs, reactive } from 'vue'
import validate from '@/validate'
import { ElMessage } from 'element-plus'
import { v4 as uuidv4 } from 'uuid'
import * as util from '@/util'
import * as auth from '@/auth.js'
const { sendPhoneCode, signInWithPhoneCode } = auth
import * as api from '@/service'
const { fetchUserAuth, bind } = api

export default {
  name: 'Login',
  setup() {
    const TIME = 60

    const state = reactive({
      form: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: validate.phone, trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      loginLoading: false,
      disabled: false,
      btnText: '获取验证码',
      time: TIME,
      timer: null
    })

    // 倒计时
    const countDown = () => {
      if (state.time > 1) {
        state.disabled = true
        state.time--
        state.btnText = `${state.time}秒后重发`
        state.timer = setTimeout(() => {
          countDown()
        }, 1000)
      } else {
        state.disabled = false
        state.time = TIME
        state.btnText = '获取验证码'
        state.timer = null
      }
    }

    return {
      ...toRefs(state),
      countDown
    }
  },
  methods: {
    // 注册
    login() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          const { phone, code } = this.form
          try {
            this.loginLoading = true
            const defaultPwd = uuidv4()
            const { user } = await signInWithPhoneCode(phone, code, defaultPwd)
            // 查看权限
            const { total, data } = await fetchUserAuth(phone)
            if (total > 0) {
              this.$store.commit('SET_USER_AUTH', data[0])
            }
            // 绑定 chatGPT ID
            await bind({
              mobile: phone,
              wechatId: user.uid
            })
            this.loginLoading = false
            this.$emit('on-login')
          } catch (error) {
            this.loginLoading = false
            console.log(error)
            ElMessage.error(util.getError(error))
          }
        }
      })
    },
    // 获取验证码
    async getCode() {
      const { phone } = this.form
      if (phone.trim() === '') {
        return ElMessage.error('请输入手机号码')
      }
      if (validate.phoneFormat(phone)) {
        try {
          const result = await sendPhoneCode(phone)
          if (result) {
            ElMessage.success('发送成功')
            this.countDown()
          }
        } catch (error) {
          ElMessage.error(util.getError(error))
        }
      } else {
        ElMessage.error('请输入正确的手机号码')
      }
    }
  }
}
</script>

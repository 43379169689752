<template>
  <footer class="footer">
    <div class="main-container">
      <div class="info">
        <div class="l">
          <img :src="require('@/assets/img/cbnb_logo.png')" />
        </div>
        <div class="c">
          <h4>联系方式</h4>
          <p>电话：0574-87428826</p>
          <p>时间：9:00-17:00（工作日）</p>
          <p>地址：宁波市鄞州区天童南路666号</p>
        </div>
        <div class="r">
          <div class="qr-wrap">
            <img :src="require('@/assets/img/kefu.jpg')" />
            <span>在线客服</span>
          </div>
          <div class="qr-wrap">
            <img :src="require('@/assets/img/kefu.jpg')" />
            <span>公众号</span>
          </div>
        </div>
      </div>
      <p class="copyright">
        Copyright ©️ 2022 中基天时（宁波）创新科技有限公司. All rights reserved. <a target="_blank" href="https://beian.miit.gov.cn/" style="margin-left: 30px">备案号 浙ICP备19018220号-2</a>
      </p>
    </div>
  </footer>
</template>

<style lang="less">
.info {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  justify-content: space-between;
}
.footer {
  padding-top: 40px;
  background-color: #fff;
  .l,
  .c,
  .r {
    flex: 1;
  }
  .l {
    img {
      width: 220px;
      object-fit: contain;
    }
  }
  .c {
    padding-left: 60px;
    h4 {
      font-size: 22px;
      margin-top: 0;
    }
    h4,
    p {
      color: #000;
    }
    p {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
  .r {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.qr-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  span {
    color: #000;
  }
  img {
    width: 120px;
    margin-bottom: 10px;
  }
}
p.copyright {
  margin: 0;
  color: #999;
  border-top: 1px solid #ddd;
  height: 70px;
  line-height: 70px;
  text-align: center;
  a {
    color: #999;
    text-decoration: none;
  }
}
</style>

<template>
  <el-dialog v-model="show" class="login-dialog" size="small" title="预约报名" width="400px">
    <el-form ref="reserveForm" :model="form" v-loading="loading" :rules="rules">
      <el-form-item prop="name">
        <el-input v-model="form.name" placeholder="联系人" />
      </el-form-item>
      <el-form-item prop="phone">
        <el-input v-model="form.phone" placeholder="联系方式" />
      </el-form-item>
      <el-form-item prop="company">
        <el-input v-model="form.company" placeholder="公司名称" />
      </el-form-item>
      <el-form-item prop="message">
        <el-input v-model="form.message" type="textarea" :rows="3" placeholder="留言" />
      </el-form-item>
      <el-form-item style="margin-bottom: 0">
        <el-button style="width: 100%" @click="loading ? null : submit()" :loading="loading" type="primary">立即预约</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { toRefs, reactive } from 'vue'
import validate from '@/validate'
import { ElMessage } from 'element-plus'
import * as api from '@/service'
const { createReserve } = api

export default {
  name: 'Reserve',
  setup() {
    const state = reactive({
      show: false,
      loading: false,
      form: {
        name: '',
        phone: '',
        company: '',
        message: ''
      },
      id: '',
      rules: {
        name: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入联系人手机号码', trigger: 'blur' },
          { validator: validate.phone, trigger: 'blur' }
        ],
        company: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        message: [{ required: true, message: '请输入留言信息', trigger: 'blur' }]
      }
    })

    return {
      ...toRefs(state)
    }
  },
  methods: {
    async submit() {
      this.$refs.reserveForm.validate(async (valid) => {
        if (valid) {
          const { name, phone, company, message } = this.form
          try {
            this.loading = true
            await createReserve({
              name,
              phone,
              company,
              message,
              reserveType: this.id
            })
            this.loading = false
            ElMessage.success('预约成功')
            this.show = false
            this.form = {
              name: '',
              phone: '',
              company: '',
              message: ''
            }
          } catch (error) {
            this.loginLoading = false
            console.log(error)
            ElMessage.error('预约失败')
          }
        }
      })
    }
  }
}
</script>

export default {
  phone: (rule, value, callback) => {
    if (!/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(value.trim())) {
      callback(new Error('请输入正确的手机号码'))
    } else {
      callback()
    }
  },
  phoneFormat: (phone) => {
    return /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/.test(phone.trim())
  }
}
<template>
  <common-header />
  <div class="home-bg">
    <!-- banner -->
    <div class="home-banner-wrap">
      <div class="home-banner-inner">
        <swiper
          class="swiper-container"
          :navigation="true"
          :space-between="30"
          :modules="modules"
          slides-per-view="auto"
          :centered-slides="true"
          :loop="true"
          :loopedSlides="banners.length + 2"
          :slidesOffsetAfter="330"
          :initialSlide="2"
          :autoplay="true"
          :delay="3000"
        >
          <swiper-slide class="swiper-slide" v-for="(item, k) in banners" :key="k">
            <img :src="item.banner" @click="bannerAction(item)" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 服务 -->
    <div class="service-wrap">
      <div class="item">
        <img :src="require('@/assets/img/icon_1.png')" />
        <h4>服务专业可靠</h4>
      </div>
      <div class="item">
        <img :src="require('@/assets/img/icon_2.png')" />
        <h4>7*24快速响应</h4>
      </div>
      <div class="item">
        <img :src="require('@/assets/img/icon_3.png')" />
        <h4>全面系统培训</h4>
      </div>
      <div class="item">
        <img :src="require('@/assets/img/icon_4.png')" />
        <h4>累计服务客户2000+</h4>
      </div>
    </div>
    <div class="server-title">
      <h1>跨境综合服务</h1>
      <h4>中基天时是中国500强企业<span class="line"></span>中基宁波集团重点打造的集“金融服务、出口贸易服务、仓储物流服务、ERP服务” <br />等为一体的创新型跨境综合服务平台.</h4>
    </div>
    <!-- 两大板块 -->
    <div class="main-container part">
      <div class="part-wrap bg-1" style="margin-bottom: 45px">
        <div class="white-t" style="left: 40px; bottom: 65px">
          <h1>供应链服务</h1>
          <p style="width: 410px">数字化客户信用评级体系，根据客户评级为客户提供采购、物流、仓储、配送全链路供应链金融服务。</p>
        </div>
        <div class="red-t" style="left: 538px; bottom: 65px">
          <h1>出口贸易服务</h1>
          <p style="width: 450px">一站式智能化跨境出口贸易平台为客户在出口贸易中提供采购、通关、外汇、退税、信保、物流仓配等全链路服务。</p>
        </div>
      </div>
      <div class="part-wrap bg-2">
        <div class="red-t" style="left: 40px; bottom: 55px">
          <h1>仓储物流服务</h1>
          <p style="width: 450px">为客户提供海运、空运、海内外拖卡、清关、前置仓、海外仓、尾程配送等全链路综合物流服务。</p>
        </div>
        <div class="white-t" style="left: 740px; bottom: 35px">
          <h1>数智服务</h1>
          <p style="width: 425px">为客户提供高效及时的数据分析和管理包括：货物采购、资金使用、销售、物流(在途、出仓、入仓 )等，全程可视化数据。</p>
        </div>
      </div>
    </div>
    <!-- 核心 -->
    <div class="core">
      <div class="main-container">
        <div class="core-wrap">
          <div class="core-item">
            <h2><img :src="require('@/assets/img/zy.png')" /> <strong>专业</strong></h2>
            <span>订单数据进行分析<br />判断运营健康程度<br />提供数据依据</span>
          </div>
          <div class="core-item">
            <h2><img :src="require('@/assets/img/aq.png')" /> <strong>安全</strong></h2>
            <span>极大的降低了运营风险<br />数据加密处理<br />保障用户权益</span>
          </div>
          <div class="core-item">
            <h2><img :src="require('@/assets/img/ax.png')" /> <strong>高效</strong></h2>
            <span>简洁直观的店铺数据<br />海量的产品库<br />帮助新手卖家迅速起步</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <h1 class="partner-title">合作伙伴</h1>
    <div class="main-container mb-40">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="partner">
            <img :src="require('@/assets/img/amazon_logo.jpg')" />
            <span>亚马逊</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="partner">
            <img :src="require('@/assets/img/ebay_logo.jpg')" />
            <span>eBay</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="partner">
            <img :src="require('@/assets/img/xindan_logo.jpg')" />
            <span>newegg</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="partner">
            <img :src="require('@/assets/img/pan_logo.jpg')" />
            <span>派安盈收款</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- footer -->
    <common-footer />
  </div>

  <!-- 预约 -->
  <reserve ref="reserveRef" />
</template>

<script>
import CommonHeader from '@/components/CommonHeader.vue'
import CommonFooter from '@/components/Footer.vue'
import Reserve from '@/components/Reserve.vue'
import { toRefs, reactive } from 'vue'
import * as api from '@/service'
const { fetchAds } = api
import { Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/navigation'

export default {
  components: {
    CommonHeader,
    CommonFooter,
    Reserve,
    Swiper,
    SwiperSlide
  },
  setup() {
    const state = reactive({
      loading: true,
      banners: []
    })

    return {
      ...toRefs(state),
      modules: [Navigation, Autoplay]
    }
  },
  methods: {
    async fetchAds() {
      try {
        const { data } = await fetchAds()
        this.banners = data
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    bannerAction(banner) {
      const { type, url, reserve } = banner

      switch (type) {
        // 预约
        case 1:
          // eslint-disable-next-line no-case-declarations
          const { _id } = reserve
          // eslint-disable-next-line no-case-declarations
          const reserveModal = this.$refs.reserveRef
          reserveModal.id = _id
          reserveModal.show = true
          break
        // 本地路由
        case 2:
          try {
            this.$router.push(banner.params)
          } catch (error) {
            console.log(error)
          }
          break
        // 外链
        case 3:
          window.open(url)
          break
        default:
          break
      }
    }
  },
  async created() {
    await this.fetchAds()
  }
}
</script>

<style lang="less">
.white-t,
.red-t {
  position: absolute;
  h1 {
    font-size: 40px;
    margin: 0;
    margin-bottom: 20px;
  }
  p {
    font-size: 17px;
    margin: 0;
    text-align: justify;
  }
}
.white-t {
  h1,
  p {
    color: #fff;
  }
}
.red-t {
  h1,
  p {
    color: #000;
  }
}
.server-title {
  margin-bottom: 40px;
  h1,
  h4 {
    text-align: center;
    margin: 0;
  }
  h1 {
    font-size: 62px;
    position: relative;
    margin-bottom: 30px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      display: inline-block;
      width: 64px;
      height: 38px;
      background-image: url('../assets/img/new.png');
      background-size: 64px 38px;
      margin-left: 190px;
    }
  }
  h4 {
    font-size: 22px;
    font-weight: normal;
  }
}
.home-bg {
  padding-top: 20px;
  background-color: #fff;
  background-image: url('../assets/img/h_bg.png');
  background-repeat: no-repeat;
  background-size: 1920px 2415px;
  background-position: center 700px;
}
.home-banner-wrap {
  width: 100%;
  height: 558px;
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
}
.home-banner-inner {
  position: absolute;
  left: 50%;
  margin-left: -960px;
}
.swiper-button-prev {
  left: 50%;
  margin-left: -580px;
}
.swiper-button-next {
  right: 50%;
  margin-right: -580px;
}
.swiper-button-prev,
.swiper-button-next {
  width: 44px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 99px;
  &:after {
    font-size: 22px;
    font-weight: bolder;
    color: #fff;
  }
  &:hover {
    &:after {
      color: #f53169;
    }
  }
}
.swiper-container {
  width: 1920px;
  height: 558px;
}
.swiper-slide {
  width: 1200px;
  background-color: #ddd;
  border-radius: 26px;
  img {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    object-fit: cover;
  }
}
.line {
  display: inline-block;
  width: 40px;
  height: 2px;
  margin: 0 5px 2px;
  background-color: #000;
  vertical-align: middle;
}

.service-wrap {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto 30px;
  position: relative;
  z-index: 10;

  .item {
    width: ((1200px - 20px * 3) / 4);
    height: 305px;
    border-radius: 16px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:hover {
      background-color: #fef8fa;
    }
    img {
      width: 95px;
      height: 95px;
    }
    h4 {
      font-size: 25px;
      margin: 8px 0 0;
    }
  }
}
.part {
  padding-top: 30px;
}
.part-wrap {
  position: relative;
  display: flex;
  height: 486px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  .half {
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 40px;
    }
    h4 {
      font-size: 30px;
    }
    h1,
    h4 {
      text-align: center;
      margin: 0;
    }
    .icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 40px;
    }
    .icon-wrap {
      margin: 0 30px;
      justify-content: center;
      display: flex;
      align-items: center;
      flex: 1;
      span {
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
  }
}
.bg-1 {
  background-image: url('../assets/img/bg_1.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-2 {
  background-image: url('../assets/img/bg_2.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.core {
  margin-top: 80px;
  padding: 40px 0;
  background: #fff;
}
.core-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  justify-content: space-around;
  .core-item {
    position: relative;
    padding-bottom: 160px;
    padding-left: 80px;
    flex: 1;
  }
  h2 {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    strong {
      font-size: 40px;
    }
  }
  span {
    display: inline-block;
    margin-top: 20px;
    line-height: 2.2;
    font-size: 18px;
  }
  img {
    position: absolute;
    width: 150px;
    bottom: 10px;
    right: 40px;
  }
}
.partner-title {
  margin: 40px 0 40px;
  text-align: center;
}
.partner {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  img {
    width: 100%;
    margin-bottom: 10px;
  }
  span {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>

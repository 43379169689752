export default [
  {
    path: '/center',
    name: 'AdminCenter',
    redirect: () => ({
      name: 'AdminProfile'
    }),
    component: () => import('@/views/admin/center'),
    meta: {
      auth: true
    },
    children: [
      {
        path: 'profile',
        name: 'AdminProfile',
        component: () => import('@/views/admin/profile'),
        meta: {
          auth: true,
        },
      }, {
        path: 'scripts',
        name: 'AdminScripts',
        component: () => import('@/views/videoscript/List'),
        meta: {
          auth: true,
          role: 'VIDEO_CREATOR'
        },
      },
      {
        path: 'scriptCreate',
        name: 'AdminCreateScript',
        component: () => import('@/views/videoscript/CreateAndUpdate'),
        meta: {
          auth: true,
          role: 'VIDEO_CREATOR'
        }
      },
      {
        path: 'scriptUpdate/:id',
        name: 'AdminUpdateScript',
        component: () => import('@/views/videoscript/CreateAndUpdate'),
        meta: {
          auth: true,
          role: 'VIDEO_CREATOR'
        }
      },
      {
        path: 'analyzeVideo',
        name: 'AdminAnalyzeVideo',
        component: () => import('@/views/analyzeVideo/List'),
        meta: {
          auth: true,
          role: 'VIDEO_CREATOR'
        }
      },
      {
        path: 'onAuth',
        name: 'AdminNoAuth',
        component: () => import('@/views/Permissions'),
        meta: {
          auth: true
        }
      },
    ]
  },
]
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = createStore({
  state() {
    return {
      userAuth: null,
    }
  },
  mutations: {
    SET_USER_AUTH(state, user) {
      state.userAuth = user
    }
  },
  plugins: [vuexLocal.plugin]
})

export default store
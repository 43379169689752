import * as VueRouter from 'vue-router'
import admin from './admin'
import * as cloudAuth from '@/auth'

import Home from '@/views/Home'
import NotFound from '@/views/NotFound'
import Permissions from '@/views/Permissions'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/service/List'),
  },
  {
    path: '/serviceDetail/:id/:cIndex',
    name: 'ServiceDetail',
    component: () => import('@/views/service/Detail'),
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/products/List'),
  },
  {
    path: '/productsDetail/:id',
    name: 'ProductsDetail',
    component: () => import('@/views/products/Detail'),
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: () => import('@/views/Aboutus'),
  },
  {
    path: '/chatGPT',
    name: 'chatGPT',
    component: () => import('@/views/chatGPT/Chat'),
  },
  {
    path: '/chatHistory/:uid',
    name: 'ChatHistory',
    component: () => import('@/views/chatGPT/History'),
  },
  ...admin,
  { path: '/permission', name: 'Permissions', component: Permissions },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const { auth, role } = to.meta
  const status = cloudAuth.loginStatus()

  const { userAuth } = store.state

  // 路由是否需要登录
  if (auth) {

    // 是否登录
    if (status) {

      // 如果需要权限
      if (role) {

        if (userAuth) {
          const roles = userAuth.roles.map(v => v.role)
          if (roles.indexOf(role) >= 0) {
            next()
            return
          }
        }

        router.replace({
          name: 'AdminNoAuth',
          query: {
            from: to.name
          }
        })
        return
      }

      next()
      return
    }

    router.replace({
      name: 'Login'
    })
    return

  }

  if (status && to.name === 'Login') {
    router.replace({
      name: 'AdminCenter'
    })
    return
  }

  next()
})

export default router
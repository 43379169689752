import axios from 'axios'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const API = 'https://cloud1-6ge47kdb3eb742e5-1312796281.ap-shanghai.service.tcloudbase.com/zbn/v1.0'

// 获取用户权限
export const fetchUserAuth = (phone) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/user/find`,
      method: 'POST',
      data: {
        query: {
          'phone': {
            $eq: phone
          },
          'status': {
            $eq: 1
          }
        }
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取脚本列表
export const fetchScripts = ({ size = 20, page = 1, data = {} }) => {
  return new Promise((resolve, reject) => {
    const skip = (page - 1) * size
    axios({
      url: `${API}/scripts/find`,
      method: 'POST',
      params: {
        limit: size,
        skip
      },
      data
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 新建脚本
export const createScripts = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/scripts`,
      method: 'POST',
      data: {
        data: [data]
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 修改脚本
export const updateScripts = (docId, data) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/scripts/${docId}`,
      method: 'PATCH',
      data: {
        data
      }
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取选题
export const fetchTopic = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/topic`,
      params: {
        limit: 50
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取爆款元素
export const fetchHot = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/hotElement`,
      params: {
        limit: 50
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 修改脚本状态
export const switchStatus = (docId, status) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/scripts/${docId}`,
      method: 'PATCH',
      data: {
        data: {
          status
        }
      }
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// 通过 docId 获取脚本
export const getScriptById = (docId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/scripts/${docId}`,
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取服务分类
export const fetchServicesCategory = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/zbn_category`,
      params: {
        limit: 50
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data.data)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取服务列表
export const fetchServiceList = ({ size = 20, page = 1, data = {} }) => {
  return new Promise((resolve, reject) => {
    const skip = (page - 1) * size
    axios({
      url: `${API}/zbn_products/find`,
      method: 'POST',
      params: {
        limit: size,
        skip
      },
      data
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      } else {
        reject(res)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 服务详情
export const fetchServiceDetail = (docId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/zbn_products/${docId}`,
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

const ChatGPT_API = 'https://cbec.seedslinks.com/api/gpt/'
const token = 'bEmve2e6GOWcwZcS1YGdIYvvYTe8FXnWTNiLNpgKM0DujZLdGziURnXGRUnTl43S'
const Bearer = `Bearer ${token}`

// chatGPT
export const sendMessage = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${ChatGPT_API}completions`,
      data,
      headers: { 'Authorization': Bearer },
      method: 'POST',
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.isError === 0) {
          resolve(result.data.data)
        } else {
          reject(result.data)
        }
      }
    }).catch(error => {
      reject(error)
    })
  })
}

// 绑定
export const bind = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${ChatGPT_API}subscribe`,
      data,
      headers: { 'Authorization': Bearer },
      method: 'POST',
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.isError === 0) {
          resolve(result.data.data)
        } else {
          reject(result.data)
        }
      }
    }).catch(error => {
      reject(error)
    })
  })
}

// 获取聊天记录
export const fetchMessageHistory = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${ChatGPT_API}conversations?wechatId=${id}`,
      headers: { 'Authorization': Bearer },
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.isError === 0) {
          resolve(result.data.data)
        } else {
          reject(result.data)
        }
      }
    }).catch(error => {
      reject(error)
    })
  })
}

// 删除聊天
export const messageDelete = (id, conversationId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${ChatGPT_API}remove?wechatId=${id}&conversationId=${conversationId}`,
      headers: { 'Authorization': Bearer },
      data: {},
      method: 'DELETE',
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.isError === 0) {
          resolve(result.data)
        } else {
          reject(result.data)
        }
      }
    }).catch(error => {
      reject(error)
    })
  })
}

// 聊天详情
export const messageDetail = (id, conversationId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${ChatGPT_API}history?wechatId=${id}&conversationId=${conversationId}`,
      headers: { 'Authorization': Bearer },
    }).then((result) => {
      if (result.status === 200) {
        if (result.data.isError === 0) {
          resolve(result.data.data)
        } else {
          reject(result.data)
        }
      }
    }).catch(error => {
      reject(error)
    })
  })
}

// 获取产品详情
export const fetchProductDetail = (docId) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/products/${docId}`,
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 获取产品详情
export const fetchAds = () => {
  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/ads`,
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 提交预约
export const createReserve = (data) => {

  return new Promise((resolve, reject) => {
    axios({
      url: `${API}/reserve`,
      method: 'POST',
      data: {
        data: [data]
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        resolve(res.data)
      }
    }).catch(err => {
      reject(err)
    })
  })
}
const ERROR = {
  '[PHONE_CODE_NOTFOUND_OR_EXPIRED]': '请先发送验证码',
  '[PHONE_CODE_NOT_MATCHED]': '手机验证码错误',
  '[PHONE_CODE_SEND_LIMIT_EXCEED]': '操作频繁，请稍后再试'
}

export const getError = (error) => {
  try {
    const errKey = error.message.split(' ')[0]
    return ERROR[errKey] ? ERROR[errKey] : '未知错误'
  } catch (error) {
    return '未知错误'
  }
}

export const getUserPhone = (phone) => {
  return {
    str: phone.substr(3, 3) + '****' + phone.substr(10),
    phone: phone.substr(3, phone.length)
  }
}